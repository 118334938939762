'use strict';

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import { action, makeObservable, makeAutoObservable } from "mobx";
import classnames from 'classnames';

import {FaGoogle} from 'react-icons/fa';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import appStore from '@uw-it-sis/lib-react/lib/AppStore';
import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import SimpleState from "@uw-it-sis/lib-react/lib/SimpleState";
import PlanSharedSelector from '@uw-it-sis/lib-react-myplan/lib/PlanSharedSelector';
import notifyStore from '@uw-it-sis/lib-react-myplan/lib/NotifyStore';
import DocumentTitle from '@uw-it-sis/lib-react/lib/DocumentTitle';

const NotifyEndPoint = (props) => {
    let endpoint = notifyStore.getEndPoint(props.protocol);
    let endpointAddress = "Not Found. ";
    let hasEndpointAddress = false;
    let editLink = null;

    if (endpoint) {
        hasEndpointAddress =
            endpoint.hasOwnProperty('EndpointAddress') &&
            endpoint.EndpointAddress !== '' &&
            endpoint.EndpointAddress !== null;

        if (hasEndpointAddress) {
            endpointAddress = endpoint.EndpointAddress;
        }
    } else {
        let notifyUrl = `${appStore.getNotifyUrl()}/notify/#/?edit_contacts=true`
        editLink = <a href={notifyUrl} target="_blank">Edit</a>
    }

    return (
        <>
            <div><strong>{props.title}:</strong></div>
            <div className={classnames({"text-muted": !hasEndpointAddress})}>{endpointAddress}{editLink}</div>
        </>
    );
}

NotifyEndPoint.propTypes = {
    title: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired
};

class Account extends Component {

    constructor(props) {
        super(props);
        this.store = notifyStore;

        this.buildDoneComponent = this.buildDoneComponent.bind(this);
    }

    componentDidMount() {
        // Don't try to load notify data for social users or advisers
        if (appStore.isAuthenticated()){
            if ( appStore.isSocialUser() || appStore.isAdviser() ) {
                this.store.state = States.done;
            } else {
                this.store.loadNotifyData();
            }
        } else {
            appStore.doLogin();
        }
    }

    buildDoneComponent() {
        let importPlan, notifySettings, planShare;

        // Give info to social users about ability to import plan in future
        if ( appStore.isSocialUser() ) {
            importPlan =
                <Card bg="transparent" className="border mb-4">
                    <Card.Header>
                        <Card.Title as="h2" className="mb-0">Import Plan from Another Account</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <p>If you're using MyPlan with a Google login, you will be able to import your academic
                            plan to your official UW account once you are accepted to the University of Washington. Note:
                            This will overwrite any plan you create with your UW NetID account.</p>
                        <p><a href="https://itconnect.uw.edu/learn/tools/myplan-academic-planner/import-plan/" target="_blank">Learn more about
                            importing your plan</a>.</p>
                    </Card.Body>
                </Card>;
        }
        // We should only display social Import Link and plan Shared Selector for UW students.
        if ( appStore.isStudent() ) {
            importPlan =
                <Card bg="transparent" className="border mb-4">
                    <Card.Header>
                        <Card.Title as="h2" className="mb-0">Import Plan from Another Account</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <p>Import an academic plan you created with your Google account.</p>
                        <p><strong>Note:</strong> This will overwrite any plan you have created with your UW NetID account. <a href="https://itconnect.uw.edu/learn/tools/myplan-academic-planner/import-plan/" target="_blank">Learn more about importing the plan</a>.</p>
                        <p><a href="/plan/#/import" className="fw-bold">Start the import process</a></p>
                    </Card.Body>
                </Card>;

            planShare =
                <Card bg="transparent" className="border mb-4">
                    <Card.Header>
                        <Card.Title as="h2" className="mb-0">MyPlan Privacy Settings</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <PlanSharedSelector/>
                    </Card.Body>
                </Card>;
        }

        // We should only display notify link for UW users.
        if ( ! appStore.isSocialUser() && ! appStore.isAdviser() ) {
            let notifyInfo;
            // If user has no endpoints, get some extra info.
            if ( !notifyStore.hasEndPoints() ) {
                notifyInfo = <p className="mt-3 mb-0"><a href="https://itconnect.uw.edu/learn/tools/notify-uw/" target="_blank">Learn more about the Notify.UW course notification service</a> and set up your information in <a href={appStore.getNotifyUrl()} target="_blank">Notify.UW</a>.</p>;
            }

            notifySettings =
                <Card bg="transparent" className="border mb-4">
                    <Card.Header>
                        <Card.Title as="h2" className="mb-0">Notify.UW Settings</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <p>Manage your contact methods (email vs. SMS/text) and all seat availability notifications in <a href={appStore.getNotifyUrl()} target="_blank">Notify.UW</a>. Find <a href="https://itconnect.uw.edu/learn/tools/notify-uw/" target="_blank">helpful tips about Notify.UW</a>.</p>
                        <Row>
                            <Col xs={12} sm={6} md={4} lg={3}>
                                <NotifyEndPoint title="Email" protocol="email" />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={3} className="mt-3 mt-sm-0">
                                <NotifyEndPoint title="SMS / Text" protocol="sms" />
                            </Col>
                        </Row>
                        {notifyInfo}
                    </Card.Body>
                </Card>;
        }

        const ProviderIcon = appStore.getAuthenticationProviderIcon();

        return (
            <>
                <Card bg="transparent" className="border mb-4">
                    <Card.Header>
                        <Card.Title as="h2" className="mb-0">User Name</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <dl className="row mb-0 description-term-colon">
                            <dt className="col-12 col-md-2 text-start mb-0">Signed in as</dt>
                            <dd className="col-12 col-md-3 mb-0">
                                <ProviderIcon className="me-2 fs-5" />
                                <span>{appStore.getUserName()}</span>
                            </dd>
                        </dl>
                    </Card.Body>
                </Card>
                {importPlan}
                {notifySettings}
                {planShare}
            </>
        );
    }

    render() {
        return (
            <DocumentTitle title="Account - MyPlan">
                <>
                    <div className="page-heading">
                        <h1>Account</h1>
                    </div>
                    <SimpleState state={this.store.state}
                                 doneComponentBuilder={this.buildDoneComponent}
                                 pendingComponentBuilder="dots"
                                 name="Account"/>
                </>
            </DocumentTitle>
        );
    }
}

export default observer(Account);