import { observable, configure, action, observe, makeObservable } from "mobx";
configure({enforceActions: 'always'});

import {States} from '@uw-it-sis/lib-react/lib/AppConstants';
import termStore from "@uw-it-sis/lib-react/lib/TermStore";
import {getAggregateState} from "@uw-it-sis/lib-react/lib/StateUtils";
import appStore from "@uw-it-sis/lib-react/lib/AppStore";
import events from '@uw-it-sis/lib-react/lib/Events';
import NowDateTimeEvents from '@uw-it-sis/lib-react/lib/NowDateTimeEvents';

class HomeStore {
    state = States.initial;

    constructor() {
        makeObservable(this, {
            state: observable,
            mount: action,
            onSetNow: action
        });
    }

    mount() {
        this.state = States.pending;
        /*
         * Observe the term store state props to know when data loads are complete.
         */
        this.termStoreDisposer = observe(termStore, "state", function (change) {
            // Set the data state for this store to the new value.
            this.state = change.newValue;
        }.bind(this));

        // Load the currentPlanableTerm, currentTerm  and publishedTerms
        termStore.load(['publishedTerms', 'currentTerm', 'currentPlanableTerm']);

        // Register a listener for the setNow event generated by the appStore.carefullySetNow()
        events.listen(NowDateTimeEvents.setNow, (newStartTime) => this.onSetNow(newStartTime));
    }

    unmount() {
        this.termStoreDisposer();

        //unregister the setNow event
        events.remove(NowDateTimeEvents.setNow, (newStartTime) => this.onSetNow(newStartTime));
    }

    /**
     * Change the state so that the component re-renders when the now time is updated
     * @param newStartTime
     */
    onSetNow(newStartTime) {
          this.state = States.pending;

        if (appStore.isDebug()) {
            console.log(`HomeStore.onSetNow: Setting now time [${newStartTime}]`, );
        }
        this.state = States.done;
    }
}
export default new HomeStore();