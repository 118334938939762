'use strict';
import {observer} from "mobx-react";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { IoCalendarOutline } from "react-icons/io5";

import appStore from '@uw-it-sis/lib-react/lib/AppStore';


class TermWeek extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    getDate(key) {
        let time = "23:59:59";
        return (
            moment(this.props.terms[0].academicCalendar.filter(function (item) {
                return (item.key === key);
            }).map(function (item) {
                return item.date;
            })[0] + ' ' + time, "YYYY-MM-DD HH:mm:ss")
        );
    }

    render() {
        let component = null;

        if (this.props.terms.length) {
            let currentTerm = this.props.terms[0];
            let nextTerm = this.props.terms[1];

            //gets the 'now' time if set by the appStore.carefullySetNow
            let now = appStore.getNow();

            let lastFinalExam = this.getDate("LastFinalExamDay");
            let lastClass = this.getDate("LastDayOfClasses");

            let isBreak = moment(now).isAfter(lastFinalExam);
            let isFinals = moment(now).isAfter(lastClass) && moment(now).isBefore(lastFinalExam);

            let timeDisplay = null;
            let termDisplay = currentTerm.name;

            if (isBreak) {
                if (currentTerm.year !== nextTerm.year) {
                    termDisplay = currentTerm.year + '/' + nextTerm.year;
                } else {
                    termDisplay = currentTerm.year;
                }
                timeDisplay = nextTerm.codeName + " Break";
            } else if (isFinals) {
                timeDisplay = "Finals Week";
            } else {
                timeDisplay = "Week " + currentTerm.currentWeek + " of " + currentTerm.maxWeeks;
            }

            component =
                <div className="d-flex align-items-center">
                    <IoCalendarOutline aria-hidden={true} size="2rem" className="text-muted me-3" />
                    <div>
                        <div>{termDisplay}</div>
                        <div className="fs-4 fw-bold lh-1">{timeDisplay}</div>
                    </div>
                </div>;
        }

        return component;
    }
}
TermWeek.propTypes = {
    terms: PropTypes.array
}
export default observer(TermWeek);