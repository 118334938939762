'use strict';

import React, {Component} from 'react';
import {observer} from "mobx-react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { VscMortarBoard } from "react-icons/vsc";
import { BsInbox } from "react-icons/bs";
import { IoSearchOutline } from "react-icons/io5";

import appStore from '@uw-it-sis/lib-react/lib/AppStore';
import DocumentTitle from '@uw-it-sis/lib-react/lib/DocumentTitle';
import termStore from "@uw-it-sis/lib-react/lib/TermStore";
import SimpleState from '@uw-it-sis/lib-react/lib/SimpleState';

import homeStore from './HomeStore';

// import AcademicCalendar from './AcademicCalendar'; // Removed for release, but keeping for future
import TermWeek from './TermWeek';

/**
 * Container for the home page.
 */
class Home extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        // Instantiate a store.
        this.store = homeStore;
        //bind 'this' so the function can be passed as a prop to SimpleState component and the this reference is not lost
        this._makeHomePage = this._makeHomePage.bind(this);
    }

    componentDidMount() {
        this.store.mount();
    }

    componentWillUnmount() {
        this.store.unmount();
    }

    _getBackgroundClass() {
        // Returns a different style class for each day of the week which corresponds to an image (7 images total)
        return "home-banner-" + ((new Date()).getDay() + 1);
    }

    _makeHomePage() {
        let myPlanUrl = appStore.getMyPlanUrl();

        let sqvUrl, sbUrl, degreeAuditUrl, planImpexUrl;

        let currentPlanableTerm = termStore.get('currentPlanableTerm');
        let currentTerm = termStore.get('currentTerm');
        let publishedTerms = termStore.get('publishedTerms');

        let currentAndPublishedTerms = [];
        if (currentTerm) currentAndPublishedTerms.push(currentTerm);
        publishedTerms.filter(function (term) {
            return term.id !== currentTerm.id;
        }).map(function (term) {
            currentAndPublishedTerms.push(term);
        });

        if (currentPlanableTerm) {
            sqvUrl =
                <li>
                    <a href={"/plan/#/" + currentPlanableTerm.shortName.toLowerCase().replace(/\s+/g, '')}>
                        View {currentPlanableTerm.codeName} {currentPlanableTerm.year}
                    </a>
                </li>;
        }

        if (appStore.isStudent() || appStore.isAdviser()) {
            degreeAuditUrl = <li><a href="/audit/#/degree">Audit Degree (DARS)</a></li>;
        }

        if (appStore.hasRoles("ADVISER")) {
            planImpexUrl = <li><a href="/impex">Adviser Sample Plans (Beta)</a></li>
        }

        let calendar = null;
        // If there is term data from the current and publish term store, then show a calendar of term data
        if (currentAndPublishedTerms.length) {
            calendar =
                <Row className="align-items-end">
                    <Col xs={7}>
                        <TermWeek terms={currentAndPublishedTerms}/>
                    </Col>
                    <Col xs={5} className="text-end">
                        <span className="d-none d-sm-inline">View the </span>
                        <a href="https://www.washington.edu/students/reg/calendar.html" target="_blank">Academic Calendar</a>
                    </Col>
                </Row>;
        }

        let notices = null;
        if (calendar) {
            notices =
                <Row className="border-bottom border-bottom-2">
                    <h2 className="sr-only">Prepare for registration</h2>
                    <Col xs={12} lg={10} className="mx-auto mt-4 mb-3">
                        {calendar}
                    </Col>
                </Row>;
        }
        return (
            <DocumentTitle title="UW MyPlan">
                <>
                    <Row>
                        <div className={"home-banner " + this._getBackgroundClass()}>
                            <h1 className="sr-only">MyPlan</h1>
                        </div>
                    </Row>
                    {notices}
                    <Row>
                        <Col xs={12} sm={8} md={6} className="mx-auto text-center my-5">
                            <h2>Create and manage your academic plan</h2>
                        </Col>
                        <Col xs={10} sm={8} md={10} className="mx-auto">
                            <nav aria-label="MyPlan" className="row mb-5">
                                <Col xs={12} md className="d-flex align-items-start">
                                    <h3 className="sr-only" id="search-heading">Explore Courses and Programs</h3>
                                    <IoSearchOutline aria-hidden={true} size="2.5rem" className="me-3 text-muted" />
                                    <ul className="list-unstyled mb-0 fs-5 fw-light" aria-labelledby="search-heading">
                                        <li><a href="/course">Find Courses</a></li>
                                        <li><a href="/program">Find Programs</a></li>
                                        <li><a href="/course/#/bookmarks">View Course Bookmarks</a></li>
                                        <li><a href="/program/#/bookmarks">View Program Bookmarks</a></li>
                                    </ul>
                                </Col>
                                <Col xs={12} md className="d-flex align-items-start">
                                    <h3 className="sr-only" id="plan-heading">Manage Your Plan</h3>
                                    <BsInbox aria-hidden={true} size="2.5rem" className="me-3 text-muted" />
                                    <ul className="list-unstyled mb-0 fs-5 fw-light" aria-labelledby="plan-heading">
                                        <li><a href="/plan">View Academic Year</a></li>
                                        {sqvUrl}
                                        <li><a href="/plan/#/schedule?src=home">Build a Schedule</a></li>
                                        {planImpexUrl}
                                    </ul>
                                </Col>
                                <Col xs={12} md className="d-flex align-items-start">
                                    <h3 className="sr-only" id="track-heading">Track Progress</h3>
                                    <VscMortarBoard aria-hidden={true} size="2.5rem" className="me-3 text-muted" />
                                    <ul className="list-unstyled mb-0 fs-5 fw-light" aria-labelledby="track-heading">
                                        {degreeAuditUrl}
                                        <li><a href="/audit/#/plan">Audit Plan</a></li>
                                        <li><a href="/audit/#/equivalency">Find CTC Transfer Equivalency</a></li>
                                    </ul>
                                </Col>
                            </nav>
                        </Col>
                    </Row>
                </>
            </DocumentTitle>
        );
    }
    render() {
        return <SimpleState state={this.store.state} doneComponentBuilder={this._makeHomePage} name="Home"/>;
    }
};

export default observer(Home);